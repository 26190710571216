<template>
	<Tab :limiter="false">
		<template #title>Your saved data visuals and views</template>
		<template #content>
			<div class="tab-section__content__main tab-section__content__main--ewg">
				<div
					class="tab-section__content__card tab-section__content__card--visuals"
				>
					<div class="visuals-table">
						<div class="visuals-table__header gr gr--wrap">
							<VText
								size="small"
								weight="bold"
								classes="col col--5@tablet hide show@tablet"
							>
								Description
							</VText>
							<VText
								size="small"
								weight="bold"
								classes="col col--2@tablet hide show@tablet"
							>
								<img src="@/assets/imgs/icons/icon-arrow-n.svg" alt="" />
								Date saved
							</VText>
							<VText
								size="small"
								weight="bold"
								classes="col col--2@tablet hide show@tablet"
							>
								Actions
							</VText>
							<div class="col col--3@tablet hide show@tablet"></div>
						</div>
					</div>
					<div
						v-for="visual in visuals"
						:key="visual.id"
						class="visuals-table__item"
					>
						<div class="visuals-table__header gr gr--wrap">
							<VText size="small" classes="col col--5@tablet">
								<VText weight="bold" classes="hide show@max-tablet">
									Description
								</VText>
								<VLink size="small" @click="openLink(visual)">
									{{ visual.title }}
								</VLink>
							</VText>
							<VText size="small" classes="col col--2@tablet col--12">
								<VText weight="bold" class="hide show@max-tablet">
									<img src="@/assets/imgs/icons/icon-arrow-n.svg" alt="" />
									<span>Date saved</span>
								</VText>
								<span> {{ visual.created | date }}</span>
							</VText>
							<div class="col col--2@tablet col--12">
								<VText weight="bold" class="hide show@max-tablet">
									Actions
								</VText>
								<VButton color="outline-black" @click.stop="openLink(visual)">
									View this visual
								</VButton>
							</div>
							<div class="col col--3@tablet col--12 actions">
								<div class="share-btn">
									<img
										src="@/assets/imgs/icons/icon-share.svg"
										class="cursor-pointer"
										alt=""
										@click.stop="setActiveTooltip(visual)"
									/>
									<ShareTooltip
										@onClickOutside="setActiveTooltip(visual)"
										v-if="tooltip === visual.id"
									/>
								</div>
								<img
									v-if="['pai', 'pad'].includes(visual.type)"
									src="@/assets/imgs/icons/icon-download.svg"
									class="cursor-pointer"
									alt=""
									@click.stop="downloadVisual(visual)"
								/>
								<img
									src="@/assets/imgs/icons/icon-delete.svg"
									class="cursor-pointer"
									alt=""
									@click="deleteVisual(visual)"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>

			<CustomizeDownloadModal
				ref="modal"
				:errors="errors"
				@close="setActiveModal"
				@onSubmit="submitDownloadModalForm"
				v-if="modal"
			/>
		</template>
	</Tab>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { FETCH_VISUALS, DELETE_VISUAL } from '@/store/actions.type';

import ew from '@/mixins/ew';
import pai from '@/mixins/pai';
import pad from '@/mixins/pad';
import Errors from '@/helpers/Errors';
import CustomizeDownloadModal from '@/modals/CustomizeDownloadModal';

export default {
	name: 'YourVisualsAndViews',
	mixins: [pad, pai, ew],
	components: {
		CustomizeDownloadModal
	},
	data() {
		return {
			modal: null,
			visual: null,
			tooltip: null,
			errors: new Errors()
		};
	},
	computed: {
		...mapGetters(['paiFilters', 'padFilters']),
		...mapState({
			visuals: state => state.visual.visuals,
			ewgFilters: state => state.ewg.ewgFilters
		})
	},
	mounted() {
		this.$store.dispatch(FETCH_VISUALS);
	},
	methods: {
		async deleteVisual(visual) {
			const willDelete = await swal({
				title: 'Are you sure?',
				text: 'Once deleted, you will not be able to recover this visual!',
				buttons: true,
				dangerMode: true
			});

			if (!willDelete) return;

			await this.$store.dispatch(DELETE_VISUAL, visual);
			this.$store.dispatch(FETCH_VISUALS);
			this.$toastr.s('Success', 'Successfully removed visual');
		},
		submitDownloadModalForm(form, additional) {
			if (this.visual.type === 'pad') {
				this.submitPadDownloadModalForm(form, additional);
				this.visual = null;
				return;
			}

			this.submitPaiDownloadModalForm(form, additional);
			this.visual = null;
		},
		downloadVisual(visual) {
			this.visual = visual;
			const filters = visual.type.includes('pai') ? 'paiFilters' : 'padFilters';

			for (const key in visual.filters) {
				this[filters][key] = visual.filters[key];
			}

			this.setActiveModal('CustomizeDownloadModal', visual);
		},
		setActiveModal(modal = null, visual = null) {
			this.modal = modal;

			if (!modal || !visual) return;

			this.$nextTick(() => {
				this.$refs.modal.form.title = visual.title;
				this.$refs.modal.title = visual.title;
			});
		},
		setActiveTooltip(visual) {
			if (this.tooltip === visual.id) {
				this.tooltip = null;
				return;
			}

			this.tooltip = visual.id;
		},
		openLink(visual) {
			switch (visual.type) {
				case 'pai':
					this.setPaiRouterParams(visual.filters);
					return;
				case 'pad':
					this.setPadRouterParams(visual.filters);
					return;
				case 'engagement-windows':
					this.ewgFilters.country = visual.filters.country;
					this.setEwRouterParams(visual.filters);
					return;
				case 'snp':
                    const query = visual.filters;
					this.$router
						.push({ name: 'systems-and-policies', query })
						.catch(err => err);
					return;
			}
		}
	}
};
</script>

<style scoped>
.button--popup__card {
	bottom: auto;
	left: -38%;
	top: 136%;
	z-index: 10;
}

.share-btn {
	position: relative;
	display: initial;
}
</style>
